/* Base
***************************************************************/
html { overflow: hidden; }
body { margin: 0; padding: 1em; font-size: 62.5%; line-height: 1.4em; }

/* Typography
***************************************************************/
body { font-size:.75em; font-family: "Segoe WP ", "Segoe UI", "Arial", sans-serif; color: #555; }
h1, h2, h3{ font-family:"Segoe WP Light", "Segoe UI", "Arial", Sans-Serif; font-weight: normal; }
h4, h5, h6, th { font-family:"Segoe WP Semibold", "Segoe UI", "Arial", Sans-Serif; font-weight: normal; }
h1 { font-size: 1.8em; line-height:.95em; }
h2 { font-size: 1.4em; line-height: 1.1em; }
h3 { font-size: 1.1em; }
h4, h5, h6 { font-size: 1em; }
form { font-family: "Segoe WP", "Segoe UI", "Arial", sans-serif; color: #555; }
legend { font-family: "Segoe WP Semibold", "Segoe UI", "Arial", sans-serif; color: #555; }
input, select, textarea, button { font-family: "Segoe WP Semibold", "Segoe UI", "Arial", sans-serif; color: #444; }
blockquote { font-style: italic; margin-left: 1em; }

/* Links
***************************************************************/
a { color: #898989; text-decoration: underline; }
a:visited { color: #898989; }
a:hover { color: #444; text-decoration: none; }
a:focus { outline: 0; }
a:hover, a:active { outline: 0; }
h1 a { text-decoration: none; }

/* Grouping
***************************************************************/
div { padding-bottom: 1em; }
ul { margin-left: 1em; padding: 0; }
p { padding: 0; }
hr { border: none; height: 1px; color:#ebebeb; background-color:#ebebeb; }

/* Embedded
***************************************************************/
img { border: none; display: block; }

/* Forms
***************************************************************/
legend { margin-left:-.2em; }
fieldset { margin: 0 0 1.4em 0; padding: 0; border: none; }
input[type="text"], input[type="password"] { display: block; width: 85%; }
textarea { width: 85%; }
input[type="submit"], input[type="button"] { height: 24px; padding-left: 1em; padding-right: 1em; background-color: white; border: 1px solid grey; border-color: #dedfe0 #b9b9b9 #b9b9b9 #dedfe0; cursor: pointer; }
   
/* Tables
***************************************************************/
th, td { text-align: left; vertical-align: top; padding: .4em 1.2em 1em 0; line-height: 1.2em; }

/* Tiles
***************************************************************/
div.tiles div { width: 150px; height: 110px; float: left; margin: 0 1em 1em 0; overflow: hidden; }
div.tiles div p { padding:.5em; margin: 0; }